jQuery(document).ready(function() {
	
	
	 jQuery("a[href$=\'#\']").each(function () {
        jQuery(this).attr("href", "javascript:void(0);")
    });

    jQuery('p').each(function () {
        var $this = jQuery(this);
        if ($this.html().replace(/\s|&nbsp;/g, '').length == 0)
            $this.remove();
    });

    jQuery('.slider-for').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      autoplay: true,
      fade: true,
       adaptiveHeight: false,
      asNavFor: '.slider-nav'
    });
	
	
    jQuery('.slider-nav').slick({
      slidesToShow: 6, // 3,
      slidesToScroll: 1,
      asNavFor: '.slider-for',
      dots: false,
      arrows: false,
      centerMode: false,
      focusOnSelect: true,
      variableWidth: true,
      autoplay: true
    });
    jQuery(".mobile-menu").click(function(){
        jQuery('body').toggleClass("open-menu");
        jQuery('.fa-bars').toggleClass("fa-times");
    });
    jQuery(document).on('click', function () {
        jQuery('body').removeClass('open-menu');
        jQuery('.fa-bars').removeClass('fa-times');
    });
    jQuery('.mobile-menu,.navigation').on('click', function (e) {
        e.stopPropagation();
    });
});    

jQuery(window).load(function() {
    jQuery('.banner-section').removeClass('banner-loader');
});